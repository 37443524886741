
.App {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map {
  position: relative;
}

.map img {
  opacity: 0.6;
  filter: grayscale(0.6);
}

.map span {
  position: absolute;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #0064ff;
}

li {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

#marker1{
  top: 8%;
  left: 10%;
}

#marker2{
  top: 45%;
  left: 14%;
}

#marker3{
  top: 10%;
  left: 48%;
}

#marker4{
  top: 25%;
  left: 55%;
}

#marker5{
  top: 20%;
  left: 77%;
}

#marker6{
  top: 15%;
  left: 80%;
}

#marker7{
  top: 30%;
  left: 80%;
}

#marker8{
  top: 35%;
  left: 82%;
}

#marker9{
  top: 45%;
  left: 80%;
}

#marker10{
  top: 60%;
  left: 90%;
}

#marker11{
  top: 70%;
  left: 85%;
}

#marker12{
  top: 70%;
  left: 78%;
}

#marker13{
  top: 50%;
  left: 50%;
}

#location {
  top: 20%;
  left: 92%;
  background-color: red;
}

.map div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  font-size: 40px;
  font-weight: bold;
  color: #0064ff;
}

@keyframes highlight {
  100%{
    transform: scale(5);
    opacity: 0;
  }
}

.container {
  display: flex;
  margin: 25px;
}
